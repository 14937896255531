import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { activateExperiment } from '~/libs/api/user';
import { selectCurrentUserExperiments } from '~/state/currentUser/selectors';
import { Experiments } from '~/typings/experiment';

import { userExperimentAssignment } from '../currentUser/actions';

export const useClientsideExperiment = (
  experimentName: Experiments,
  eligible: boolean
) => {
  const userExperiments = useSelector(selectCurrentUserExperiments);
  // undefined if unassigned, or a string if already assigned
  const stateExperimentAssignment = userExperiments?.[experimentName];
  const [experimentAssignment, setExperimentAssignment] = useState(
    stateExperimentAssignment
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchVariant = async () => {
      const variant = (await activateExperiment(
        experimentName
      )) as typeof experimentAssignment;
      setExperimentAssignment(variant);
      setLoading(false);
      dispatch(
        userExperimentAssignment({
          [experimentName]: variant,
        })
      );
    };

    // if the user isn't already assigned, and they are eligible, assign them
    // otherwise, we won't assign and experimentAssignment will be 'undefined'
    if (!stateExperimentAssignment && eligible) {
      fetchVariant();
    } else {
      setLoading(false);
    }
  }, [
    setLoading,
    stateExperimentAssignment,
    eligible,
    experimentName,
    dispatch,
  ]);

  return {
    loading,
    experimentAssignment,
  };
};
