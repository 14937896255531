import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { selectActiveFeatures } from '~/state/features/selectors';

import { FeatureFlagNames } from './types';

export function useFeatureFlag(name: FeatureFlagNames): boolean {
  const selector = useMemo(() => {
    return createSelector(selectActiveFeatures, (features = []) =>
      features.includes(name)
    );
  }, [name]);

  const isEnabled = useSelector(selector);

  return isEnabled;
}
